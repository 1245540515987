<template>
  <div class="edit-password-container">
    <Title name='修改账户密码'></Title>
    <div class="content">
        <div class="navigate">
            <div class="navigate-item" 
                v-for="item in navigateOptions" 
                :key="item.step" 
                :class="{selected: currentStep == item.step}">
                {{item.name}}
            </div>
        </div>
        <div v-if="currentStep === 1" class="form-box">
            <el-form 
                label-width="120px"
                :model="smsForm"
                ref="smsForm"
                :rules="smsFormRules" >
                <el-form-item label="手机号码">
                    <span>{{hidePhone}}</span>
                </el-form-item>
                <el-form-item label="校验码" prop="code">
                    <el-input v-model="smsForm.code" style="width: 160px;margin-right: 8px;" placeholder="请输入校验码" />
                    <el-button @click="getCode" :disabled="smsBtnText !== '获取校验码'">{{smsBtnText}}</el-button>
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" @click="nextStep">下一步</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="currentStep === 2" class="form-box">
            <el-form 
                label-width="140px"
                :model="pwdForm"
                ref="pwdForm"
                :rules="pwdFormRules">
                <el-form-item label="账号名">
                    <span>{{loginName}}</span>
                </el-form-item>
                <el-form-item label="新登录密码" prop="pwd">
                    <el-input v-model="pwdForm.pwd" class="input-width" type="password" placeholder="请输入新登录密码" />
                </el-form-item>
                <el-form-item label="确认新登录密码" prop="againPwd">
                    <el-input v-model="pwdForm.againPwd" class="input-width" type="password" placeholder="请输入确认新登录密码" />
                </el-form-item>
                <el-form-item label=" ">
                    <el-button type="primary" @click="updatePwd">确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-if="currentStep === 3" class="message-box">
            <i class="icon-success el-icon-success"></i>
            <div class="title">修改登录密码成功！</div>
            <el-button size="small" @click="toCorporateInfo">返回企业信息</el-button>
        </div>
    </div>
  </div>
</template>
<script>
import {
  getCode,
  verifyCode,
  updatePwd
} from '../../api';
import Title from '../../component/Title';
export default {
  components: {
    Title
  },
  data() {
      var validatepassword1 = (rule, value, callback) => {
          var ok=true
          var pwd=true
          const FloatRegex =/^[A-Za-z0-9~@#$*]*$/;//只能包含（~@#$*）
          const FloatRegex2 =/^.{6,8}$/;//6-8
          const FloatRegex3 = /^[^\u4e00-\u9fa5]+$/;//不能中文
          const FloatRegex4 = /[A-Z]+/;//必须包含大写字母
          const FloatRegex5 = /[a-z]+/;//必须包含小写字母
          const FloatRegex6 = /[0-9]+/;//必须包含小写字母

          if(!FloatRegex.test(value)){
              ok=false;
              callback(new Error("不能出现特殊字符,只能包含（~@#$*）"))
          }
          if(!FloatRegex2.test(value)){
              ok=false;
              callback(new Error("长度6-8"))
          }

          if(!FloatRegex3.test(value)){
              ok=false;
              callback(new Error("不能出现中文"))
          }
          if(!FloatRegex4.test(value)){
              ok=false;
              pwd=false

          }
          if(!FloatRegex5.test(value)){
              ok=false;
              pwd=false
              // callback(new Error("必须包含小写字母"))
          }
          if(!FloatRegex6.test(value)){
              ok=false;
              pwd=false
              // callback(new Error("必须包含数字"))
          }
          if(!pwd){
              callback(new Error("必须包含大、小写字母和数字"))
          }

          if (ok) {
              callback()
          }
      }


      var validatePass1 = (rule, value, callback) => {
          console.log("222222222222222222222")
          if (value === '') {
              callback(new Error('请输入密码'));
          } else {
              if ('' !==this.pwdForm.againPwd) {
                  if (value !== this.pwdForm.againPwd) {
                      callback(new Error('两次输入密码不一致!'))
                  }else{
                      this.$refs.pwdForm.validateField('againPwd');
                  }
              }
              callback();

          }
      };
      var validatePass2 = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('请再次输入密码'))
          } else if (value !== this.pwdForm.againPwd) {
              callback(new Error('两次输入密码不一致!'))
          } else {
              callback()
          }
      }
    return {
      smsBtnText: '获取校验码',
      phoneNum: localStorage.getItem('tel') || '',
      loginName: localStorage.getItem('loginName') || '',
      verifyCode: '',
      currentStep: 1,
      navigateOptions: [
        { step: 1, name: '1.验证手机' },
        { step: 2, name: '2.修改登录密码' },
        { step: 3, name: '3.修改成功' }
      ],
      smsForm: {
        code: '',
        uuid: ''
      },
      pwdForm: {
          pwd: '',
          againPwd: '',
      },
      smsFormRules: {
        code: [{ required: true, message: '请输入校验码', trigger: 'blur' }],
      },
      pwdFormRules: {
        pwd:  [ { required: true, validator: validatePass1, trigger: 'blur' },
            { required: false, validator: validatepassword1, trigger: 'blur' }
        ],
        againPwd: [ { required: true, validator: validatePass2, trigger: 'blur' },
          { required: false, validator: validatepassword1, trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
      hidePhone() {
          return this.phoneNum.substr(0, 3) + '******' + this.phoneNum.substr(9, 11);
      }
  },
  methods: {
    nextStep() {
        this.$refs.smsForm.validate(valid => {
            if (valid) {
                //校验验证码
                verifyCode({ 
                    code: this.smsForm.code,
                    uuid: this.smsForm.uuid
                }).then(res =>{
                    if(res.errno == 0){
                        this.currentStep = 2; 
                    }
                })
            }
        })
    },
    getCode() {
        getCode({
            phoneNum: this.phoneNum,
            scene: "10"
        }).then(res =>{
            this.smsForm.uuid = res.data.uuid;

            let timeNum = 60;
            let timer = setInterval(() => {
                timeNum--;
                this.smsBtnText = `${timeNum}s后重新发`;
                if (timeNum < 1) {
                    clearInterval(timer);
                    this.smsBtnText = '获取校验码';
                }
            }, 1000);

          if(res.data.ssm=='9'){
            this.$message.error('今日获取验证码数量已到达上限');
            return false;
          }
        })
    },
    updatePwd() {
        this.$refs.pwdForm.validate(valid => {
            if (valid) {
                if (this.pwdForm.pwd !== this.pwdForm.againPwd) 
                    return this.$message.warning('两次输入密码不一致');
                updatePwd({
                    pwd: this.pwdForm.pwd,
                    phoneCode: this.smsForm.code,
                    phoneCodeUUID: this.smsForm.uuid
                }).then(res => {
                    if (res.errno == 0) {
                        this.currentStep = 3; 
                    }
                })
            }
        })
    },
    toCorporateInfo() {
        this.$router.push({
            path: '/User/CorporateInfo'
        });
    }
  }
}
</script>

<style lang="less">
.edit-password-container {
  background: #fff;
  min-height: 460px;
    
    .content {
        padding: 0 20px;
        box-sizing: border-box;

        .navigate {
            display: flex;
            padding: 0;
            margin: 0;
            overflow: hidden;
        

            &-item {
                position: relative;
                flex: 1;
                height: 34px;
                background: #ddd;
                // margin-right: 5px;
                text-align: center;
                line-height: 34px;
                margin-right: 24px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: -17px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-style: solid;
                    border-color: #ddd #ddd #ddd transparent;
                    border-width: 17px 0 17px 17px;
                }
                &::after {
                    position: absolute;
                    top: 0;
                    right: -17px;
                    width: 0;
                    height: 0;
                    content: '';
                    border-style: solid;
                    border-color: transparent transparent transparent #ddd;
                    border-width: 17px 0 17px 17px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                &.selected {
                    color: #fff !important;
                    background-image: linear-gradient(to right, #ACB2CA, #33A2C5);
                }
                &.selected::before {
                    border-color: #ACB2CA #ACB2CA #ACB2CA transparent;
                }
                &.selected::after {
                    border-color: transparent transparent transparent #33A2C5;
                }
            }
        }
        .form-box {
            margin: 40px auto;
            width: 460px;

            .input-width {
                margin-right: 8px;
                width: 200px;
            }
        }
        .message-box {
            margin-top: 80px;
            text-align: center;

            .icon-success {  
                font-size: 70px;       
                color: rgb(11, 189, 11);   
            }
            .title {
                margin: 10px 0 16px;
                font-size: 16px;
                font-weight: bold;
                color: #333;   
            }
            .description {
                margin-bottom: 24px;
                font-size: 14px;
                color: #333;  
            }
        }
    }
}
</style>